export const state = () => ({
  securityData: {},
  autoVideoUrl: '',
  loading: false,
  error: {},
  isEmpty: false
})

export const mutations = {
  empty_data(state) {
    state.myMarketData = []
  },
  set_data(state, data) {
    state.securityData = data
  },
  set_data_auto(state, data) {
    state.autoVideoUrl = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getNanoCosmosSecurity({ commit }, streamname) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'Member/nanocosmosLogin',
        data: { streamname }
      })
      if (
        response.data.error &&
        response.data.error &&
        response.data.error.error === 'No Data Found'
      ) {
        commit('set_isEmpty', true)
      } else {
        commit('set_isEmpty', false)
      }

      if (response.data.value) {
        commit('set_data', response.data.data)
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  getAutoVideoUrl({ commit }, eventId) {
    try {
      // const streamData = {
      //   event: eventId,
      //   user: this.$auth.user._id,
      //   _accessToken: this.$auth.getToken('customLocal')
      // }
      commit('set_loading', true)
      // const response = await this.$axios({
      //   method: 'post',
      //   baseURL: process.env.USERS_API_URL,
      //   url: 'GetStringForWatchtv/stringForTvFstAPI',
      //   data: streamData
      // })
      const response = process.env.LIVE_TV_URL + '/?eventid=' + eventId
      if (this.$auth.getToken('customLocal')) {
        commit('set_data_auto', response)
      }
    } catch (error) {
      // console.log('response', error.response.data.error)
      if (!error?.response?.data?.value) {
        commit('set_error', error.response)
      } else {
        commit('set_error', error)
      }
    }
  }
}
